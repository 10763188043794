import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { StaticQuery, graphql } from "gatsby";

// Assets
import logo from "../assets/images/8biticon.jpg";
import "./index.css";

const HeaderWrapper = styled.header`
  padding: 0.5rem 1rem;

  boxshadow: 0 4px 2px -2px gray;
`;
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;

  h1 {
    margin-bottom: 0;
  }

  h1 a {
    margin-bottom: 0.25rem;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
`;
const NavTitle = styled.h1`
  display: flex !important;
  flex-direction: column;
  display: inline-block;

  a {
    text-shadow: none;
    underline: none;
  }
`;
const Image = styled.img`
  height: 4rem;
  width: 4rem;
  margin: 0;
`;

class Header extends React.Component {
  render() {
    const { back } = this.props
    return (
      <HeaderWrapper>
        <HeaderContent>
          <NavTitle>
            <Link to={back || "/"}>Angel Piscola</Link>
          </NavTitle>
          <Image src={logo} />
        </HeaderContent>
      </HeaderWrapper>
    );
  }
}

const Copyright = () => (
  <span
    style={{
      display: `block`
    }}
  >
    {`Copyright © ${new Date().getFullYear()} `}
    {`Angel Piscola`}
  </span>
);

const FooterWrapper = styled.footer`
  font-size: smaller;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  text-align: left;
`;

export const Footer = () => (
  <FooterWrapper>
    <Copyright />
    <span>
      {`Made with `}
      <span role="img" aria-label="love" title="love">
        ❤️
      </span>
      {`, `}
      <span role="img" aria-label="sweat" title="sweat">
        💦
      </span>
      {`, `}
      {`and some `}
      <Link to="/thanks">help</Link>
    </span>
  </FooterWrapper>
);

const Main = styled.main`
  text-align: left;
  padding: 0.5rem 2rem;
  @media screen only and (min-width: 525px) {
    margin: 1.5rem auto;
    max-width: 960;
  }
`;

export default class TemplateWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    const { back, children, title } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query layout {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={({ site: { siteMetadata } }) => (
          <div>
            <Helmet>
              <title>{title || siteMetadata.title}</title>
              <meta name="description" content={siteMetadata.description} />
              <link rel="canonical" href="//angelpisco.la" />
              <link rel="canonical" href="//angelpiscola.com" />
              <link rel="canonical" href="//www.angelpiscola.com" />
              <script
                defer
                src="https://use.fontawesome.com/releases/v5.2.0/js/all.js"
              />
            </Helmet>
            <Header back={back} />
            <Main>{children}</Main>
            <Footer />
          </div>
        )}
      />
    );
  }
}
